import React, { Fragment, Component } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import SubHeader from '../components/SubHeader';
import * as ROUTES from '../constants/routes';
import gym from '../images/charity/gym.png';
import towel from '../images/charity/towel.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/style.scss';
// import * as ROUTES from '../src/constants/routes';


class Charity extends React.Component {

  render() {
    return (
        <Fragment>
      <SubHeader />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      <title> 國學院大學久我山高等学校 バスケットボール部</title>

      <div className="charity-area">

        <h2 class="charity-title-black2">ウェイト器具</h2>
        <div class="charity-underline"></div>

        <div className="charity-main">
        <div className="charity-content">
          <div className="charity-detail">
            <a href="https://buy.stripe.com/bIY6r85qr5h27iUdQS" target="_blank" rel="noopener noreferrer">
              <img alt="gym" src={gym} />
              <div className="charity-button">
              <span class="material-symbols-outlined">redeem</span>
              <div>1000円分を贈る</div>
              </div>
            </a>
          </div>
        </div>

        <div className="charity-content">
          <div className="charity-detail">
            <a href="https://buy.stripe.com/eVa4j0f119xidHicMP" target="_blank" rel="noopener noreferrer">
              <img alt="gym" src={gym} />
              <div className="charity-button">
              <span class="material-symbols-outlined">redeem</span>
              <div>5000円分を贈る</div>
              </div>
            </a>
          </div>
        </div>

        <div className="charity-content">
          <div className="charity-detail">
            <a href="https://buy.stripe.com/fZecPw7yz24Q32E148" target="_blank" rel="noopener noreferrer">
              <img alt="gym" src={gym} />
              <div className="charity-button">
              <span class="material-symbols-outlined">redeem</span>
              <div>10000円分を贈る</div>
              </div>
            </a>
          </div>
        </div>

        </div>



      </div>

    </Fragment>
);
}
}

export default () => (
  <Layout>
  <Charity />
  </Layout>
);
