export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const SAKAI = '/profileSakai';
export const IKEDA = '/profileIkeda';
export const DIGITAL = '/profileDigital';
export const PLAYER = '/profileAllPlayer';
export const TOBORI = '/obTobori';
export const KENTA = '/obKenta';
export const MUKAI = '/obMukai';
export const MANABE = '/obManabe';
export const TAKEDA = '/obTakeda';
export const WADA = '/mapWada';
export const ISHIMIZU = '/mapIshimizu';
export const ISHIZAKA = '/mapIshizaka';
export const OKABE = '/mapOkabe';
export const ARAI = '/mapArai';
export const ITOU = '/mapItou';
export const OB = '/obHome';
export const OBSAKAI = '/profileSakaiOb';
export const OBIKEDA = '/profileIkedaOb';
export const OBDIGITAL = '/profileDigitalOb';
export const OBPLAYER = '/profileAllPlayerOb';
export const OBLIVE = '/obLive';
export const CHARITY = '/charity';
export const TRAINER = '/profileTrainer';
export const HIGASHI = '/obHigashi';
export const YANAGIDA = '/obYanagida';
