import React, { Component, Fragment } from 'react';

// import Navigation from './Navigation';
import Header from './Header';
import getFirebase, { FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const script = document.createElement("script");

script.src = "https://www.gstatic.com/firebasejs/7.0.0/firebase-storage.js";
script.async = true;

document.body.appendChild(script);

    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');
    const storage = import('firebase/storage');
    Promise.all([app, auth, database, storage]).then(values => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(({ children }) => (
  <Fragment>
  <Header />
    {children}
  </Fragment>
));

export default Layout;
