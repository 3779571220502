import React from 'react';
import { Link } from 'gatsby';
import indian from '../../images/bbc_main_logo.png';
import sakai from '../../images/top_sakai.png';
import ikeda from '../../images/profile/top-manager.png';
import player from '../../images/top_player.png';
import digital from '../../images/digital_team.png';
import redeem from '../../images/redeem.png';
import trainer from '../../images/profile/top-trainer.png';

import * as ROUTES from '../../constants/routes';

class SubHeader extends React.Component {

  render() {
    return (
      <div className="profile">
      <div className="indian">
              <Link to="/">
          <img alt="indian" src={indian} />
          </Link>
      </div>
      <div className="sakai">
        <Link to={ROUTES.SAKAI}>
          <img alt="sakai" src={sakai} />
          <div className="profile-page">HC: 酒井良幸</div>
          </Link>
      </div>
      <div className="ikeda">
                          <Link to={ROUTES.IKEDA}>
          <img alt="ikeda" src={ikeda} />
                    <div className="profile-page">AC: 前田翔平</div>
                    </Link>
      </div>
      <div className="ikeda">
                          <Link to={ROUTES.TRAINER}>
          <img alt="ikeda" src={trainer} />
                    <div className="profile-page">トレーナー</div>
                    </Link>
      </div>
      <div className="sakai">
                    <Link to={ROUTES.PLAYER}>
          <img alt="sakai" src={player} />
          <div className="profile-page">選手一覧</div>
          </Link>
      </div>
      <div className="digital-header">
                        <Link to={ROUTES.DIGITAL}>
          <img alt="sakai" src={digital} />
          <div className="profile-page">デジタル運営</div>
          </Link>
      </div>
      </div>
    );
  }
}

export default SubHeader;
